import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import WorkItem from '../components/WorkItem'
import PageTitle from '../components/PageTitle'
import PageWrapper from '../components/PageWrapper'
const WorkList = ({ children, className = '' }) => (
  <ul className={`flex-auto flex flex-column list pa0 ma0 ${className}`}>
    {children}
  </ul>
)

class WorkIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = [];
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Fun and Experients"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
<PageWrapper>
        <PageTitle>Play</PageTitle>
        <WorkList>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <WorkItem key={node.fields.slug} slug={node.fields.slug}>
                {title}
              </WorkItem>
            )
          })}
        </WorkList>
        </PageWrapper>
      </Layout>
    )
  }
}

export default WorkIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
